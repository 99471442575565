<!--
* @description:
* @fileName active.vue
* @author hvv
* @date 2022/01/18 10:42:28
!-->
<template>
  <div class="type-management-container">
    <el-card
      class="filterContainer"
      shadow="always"
      style="margin-bottom: 20px"
    >
      <el-form class="demo-form-inline" :inline="true" :model="queryForm">
        <el-form-item label="活动名称">
          <el-input v-model="queryForm.title" placeholder="请输入活动名称" />
        </el-form-item>
        <el-form-item label="主办方">
          <el-input
            v-model="queryForm.organisers"
            placeholder="请输入主办方名称"
          />
        </el-form-item>

        <el-form-item label="人数">
          <el-select v-model="queryForm.peopleNum" placeholder="请选择活动人数">
            <template v-for="(item, index) in peopleNumArr" :key="index">
              <el-option :label="item.label" :value="item.value" />
            </template>
          </el-select>
        </el-form-item>

        <el-form-item label="状态">
          <el-select v-model="queryForm.screenEnum" placeholder="请选择状态">
            <el-option label="启用" value="ON" />
            <el-option label="停用" value="OFF" />
            <el-option label="草稿" value="DRAFT" />
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-date-picker
            v-model="queryForm.statrTime"
            placeholder="选择开始时间"
            type="datetime"
            value-format="YYYY-MM-DD HH:mm:ss"
          />
        </el-form-item>

        <el-form-item label="结束时间">
          <el-date-picker
            v-model="queryForm.endTime"
            placeholder="选择结束时间"
            type="datetime"
            value-format="YYYY-MM-DD HH:mm:ss"
          />
        </el-form-item>

        <el-form-item label="场馆类型">
          <el-select v-model="queryForm.fieldType" placeholder="请选择类型">
            <el-option label="馆内" value="WITHIN_VENUE" />
            <el-option label="馆外" value="OUTSIDE_VENUE" />
          </el-select>
        </el-form-item>

        <el-form-item label="省">
          <el-select
            v-model="queryForm.province"
            placeholder="请选择活动所在省份"
            @change="handleProvince()"
          >
            <template v-for="(item, index) in provinceArr" :key="index">
              <el-option :label="item.name" :value="item.code" />
            </template>
          </el-select>
        </el-form-item>

        <el-form-item label="市">
          <el-select
            v-model="queryForm.city"
            placeholder="请选择活动所在地市"
            @change="handleCity()"
          >
            <template v-for="(item, index) in cityArr" :key="index">
              <el-option :label="item.name" :value="item.code" />
            </template>
          </el-select>
        </el-form-item>

        <el-form-item label="区">
          <el-select v-model="queryForm.area" placeholder="请选择活动所在区县">
            <template v-for="(item, index) in areaArr" :key="index">
              <el-option :label="item.name" :value="item.code" />
            </template>
          </el-select>
        </el-form-item>

        <el-form-item label="活动类型">
          <el-select
            v-model="queryForm.typeIds"
            multiple
            placeholder="请选择活动类型"
          >
            <template v-for="(item, index) in actType" :key="index">
              <el-option :label="item.label" :value="item.data" />
            </template>
          </el-select>
        </el-form-item>

        <el-form-item label="活动标签">
          <el-select
            v-model="queryForm.tagIds"
            multiple
            placeholder="请选择活动标签"
          >
            <template v-for="(item, index) in actTag" :key="index">
              <el-option :label="item.label" :value="item.data" />
            </template>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="fetchData">筛选</el-button>
          <el-button type="primary" @click="resetQueryForm">置空</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-button :icon="Plus" type="primary" @click="handleEdit($event)">
          添加
        </el-button>
        <!-- <el-button :icon="Delete" type="danger" @click="handleDelete($event)">
          批量删除
        </el-button> -->
      </vab-query-form-left-panel>
      <!-- <vab-query-form-right-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入名称"
            />
          </el-form-item>
          <el-form-item>
            <el-button :icon="Search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel> -->
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column fixed label="排序值" prop="sort" show-overflow-tooltip />

      <el-table-column
        fixed
        label="活动标题"
        prop="title"
        show-overflow-tooltip
      />
      <el-table-column fixed label="开始时间" show-overflow-tooltip>
        <template #default="{ row }">
          <span>{{ handleDate(row.startTime) }}</span>
        </template>
      </el-table-column>

      <el-table-column fixed label="结束时间" show-overflow-tooltip>
        <template #default="{ row }">
          <span>{{ handleDate(row.endTime) }}</span>
        </template>
      </el-table-column>

      <el-table-column label="活动地址" prop="address" show-overflow-tooltip />
      <el-table-column label="主办方" prop="organisers" show-overflow-tooltip />
      <el-table-column
        label="活动人数"
        prop="peopleNum"
        show-overflow-tooltip
      />
      <el-table-column label="活动亮点" prop="sparkle" show-overflow-tooltip />

      <el-table-column label="状态">
        <template #default="{ row }">
          <el-select
            v-model="row.status"
            @change="statusChange(row.code, row.status)"
          >
            <el-option label="草稿" value="DRAFT" />
            <el-option label="启用" value="ON" />
            <el-option label="停用" value="OFF" />
          </el-select>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="305">
        <template #default="{ row }">
          <el-button type="primary" @click="handleEdit(row)">
            <vab-icon icon="edit-2-line" />
            编辑
          </el-button>
          <el-button type="danger" @click="handleDelete(row)">
            <vab-icon icon="delete-bin-6-line" />
            删除
          </el-button>

          <el-button type="info" @click="handleSeeDetail(row)">
            <vab-icon icon="eye-line" />
            查看
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNum"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="editRef" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import {
    defineComponent,
    defineAsyncComponent,
    getCurrentInstance,
    onMounted,
    computed,
    reactive,
    toRefs,
  } from 'vue'

  import { Delete, Plus, Search } from '@element-plus/icons'
  import {
    activeList,
    deleteActive,
    getLabelType,
    getLabelLabel,
    editActive,
  } from '@/api/active'
  import { getProvinceInfo, getCityInfo, getAreaInfo } from '@/api/shop'
  import { parseTime } from '@/utils/index'
  export default defineComponent({
    name: 'Active',
    components: {
      Edit: defineAsyncComponent(() => import('./components/activeEdit')),
    },
    setup() {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        // 省市区三级数据
        provinceArr: [],
        cityArr: [],
        areaArr: [],
        // 标签和类型
        actType: [],
        actTag: [],
        queryForm: { pageNum: 1, pageSize: 10 },
        total: 0,
        listLoading: false,
        list: [],
        layout: 'prev, pager, next',
        editRef: null,
        associationRef: null,
        peopleNumArr: [
          {
            label: '0-50',
            value: 0,
          },
          {
            label: '50-100',
            value: 1,
          },
          {
            label: '100-200',
            value: 2,
          },
          {
            label: '200-500',
            value: 3,
          },
          {
            label: '500-1000',
            value: 4,
          },
          {
            label: '1000以上',
            value: 5,
          },
        ],
        peopleNumValue: [
          [0, 50],
          [50, 100],
          [100, 200],
          [200, 500],
          [500, 1000],
          [1000, 99999],
        ],
      })

      const handleEdit = (row) => {
        if (row.code) {
          proxy.$router.push({ path: `/active/editActive`, query: { code: row.code}})
          // state.editRef.showEdit(row)
        } else {
          proxy.$router.push(`/active/addActive`)
        }
      }

      const handleSeeDetail = (row) => {
        proxy.$router.push(`/active/activeDetail/${row.code}`)
      }

      const handleDelete = (row) => {
        if (row.id) {
          proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await deleteActive({ id: row.id })
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            await fetchData()
          })
        }
        // else {
        //   if (state.selectRows.length > 0) {
        //     const ids = state.selectRows.map((item) => item.id).join()
        //     proxy.$baseConfirm('你确定要删除选中项吗', null, async () => {
        //       const { msg } = await doDelete({ ids })
        //       proxy.$baseMessage(msg, 'success', 'vab-hey-message-success')
        //       await fetchData()
        //     })
        //   } else {
        //     proxy.$baseMessage('未选中任何行', 'error', 'vab-hey-message-error')
        //   }
        // }
      }

      const queryData = () => {
        fetchData()
      }

      const fetchData = async () => {
        console.log(state.queryForm.peopleNum)
        if (state.queryForm.peopleNum == 0 || !!state.queryForm.peopleNum) {
          let key = state.peopleNumValue[state.queryForm.peopleNum]
          console.log(key)
          state.queryForm.startPeopleNum = key[0]
          state.queryForm.endPeopleNum = key[1]
        }
        state.listLoading = true
        const { data } = await activeList(state.queryForm)
        state.list = data.data
        state.total = data.total
        state.listLoading = false
      }

      const getProvinceData = async () => {
        const { data } = await getProvinceInfo()
        state.provinceArr = data
        console.log(data)
      }

      const handleDate = (val) => {
        return parseTime(val)
      }

      const handleSizeChange = (val) => {
        state.queryForm.pageSize = val
        fetchData()
      }
      const handleCurrentChange = (val) => {
        state.queryForm.pageNum = val
        fetchData()
      }

      // 处理获取地市数据
      const handleProvince = async () => {
        const { data } = await getCityInfo({ code: state.queryForm.province })
        state.cityArr = data
      }

      // 处理获取地市数据
      const handleCity = async () => {
        const { data } = await getAreaInfo({ code: state.queryForm.city })
        state.areaArr = data
      }

      // 获取所有活动类型
      const getAllType = async () => {
        const { data } = await getLabelType()
        state.actType = data
      }

      // 获取所有活动标签
      const getAllLabel = async () => {
        const { data } = await getLabelLabel({}, 'ACTIVITY')
        state.actTag = data
      }

      const resetQueryForm = () => {
        state.queryForm = { pageNumber: 1, pageSize: 20 }
        fetchData()
      }

      const statusChange = async (code, status) => {
        let obj = {
          code,
          status,
        }
        const { data } = await editActive(obj)
        proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
      }

      onMounted(() => {
        fetchData()
        getProvinceData()
        getAllType()
        getAllLabel()
      })
      return {
        ...toRefs(state),
        handleEdit,
        queryData,
        fetchData,
        handleSizeChange,
        handleCurrentChange,
        handleDelete,
        handleDate,
        handleSeeDetail,
        getProvinceData,
        handleProvince,
        handleCity,
        resetQueryForm,
        statusChange,
        Delete,
        Search,
        Plus,
      }
    },
  })
</script>
<style lang="scss" scoped></style>
